@charset "UTF-8";
@import url(./icons.css);
.--os {
  overscroll-behavior-x: contain;
  font-size: 12px; }
  .--os .windowTransitionMode-open .window-enter {
    opacity: 0;
    transform: translateY(-35px) scale(0.98); }
  .--os .windowTransitionMode-open .window-enter-active {
    opacity: 1;
    transform: translateY(0) scale(1);
    transition: all 100ms ease-out; }
  .--os .windowTransitionMode-minimize .window-exit {
    opacity: 1;
    transform: translateY(0) scale(1); }
  .--os .windowTransitionMode-minimize .window-exit-active {
    opacity: 0;
    transform: translateY(100px) scale(0.9);
    transition: all 100ms ease-out; }
  .--os .windowTransitionMode-maximize .window-enter {
    opacity: 0;
    transform: translateY(100px) scale(0.9); }
  .--os .windowTransitionMode-maximize .window-enter-active {
    opacity: 1;
    transform: translateY(0) scale(1);
    transition: all 100ms ease-out; }
  .--os .windowTransitionMode-up .window-exit {
    opacity: 1;
    transform: translateY(0) scale(1); }
  .--os .windowTransitionMode-up .window-exit-active {
    opacity: 0;
    transform: translateY(-100px) scale(0.9);
    transition: all 100ms ease-out; }
  .--os .windowTransitionMode-down .window-enter {
    opacity: 0;
    transform: translateY(-100px) scale(0.9); }
  .--os .windowTransitionMode-down .window-enter-active {
    opacity: 1;
    transform: translateY(0) scale(1);
    transition: all 100ms ease-out; }
  .--os .windowTransitionMode-close .window-exit {
    opacity: 1;
    transform: scale(1); }
  .--os .windowTransitionMode-close .window-exit-active {
    opacity: 0;
    transform: scale(0.85);
    transition: all 150ms linear; }
  .--os .fade-enter {
    opacity: 0; }
  .--os .fade-enter-active {
    opacity: 1;
    transition: all 100ms ease-out; }
  .--os .fade-exit {
    opacity: 1; }
  .--os .fade-exit-active {
    opacity: 0;
    transition: all 100ms linear; }
  .--os .minWindow-enter {
    max-width: 0 !important;
    overflow: hidden; }
  .--os .minWindow-enter-active {
    transition: all 100ms ease-out;
    max-width: 132px !important; }
  .--os .minWindow-exit {
    max-width: 132px;
    overflow: hidden; }
  .--os .minWindow-exit-active {
    max-width: 0 !important;
    transition: all 100ms linear; }
  .--os .window-enter {
    opacity: 0;
    transform: scale(0.98); }
  .--os .window-enter-active {
    opacity: 1;
    transform: translateY(0) scale(1);
    transition: all 100ms ease-out; }
  .--os .window-exit {
    opacity: 1;
    transform: scale(1); }
  .--os .window-exit-active {
    opacity: 0;
    transform: scale(0.95);
    transition: all 100ms linear; }
  .--os .transition-terminal-enter {
    opacity: 0;
    transform: translateY(100px); }
  .--os .transition-terminal-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: all 100ms ease-out; }
  .--os .transition-terminal-exit {
    opacity: 1;
    transform: translateY(0); }
  .--os .transition-terminal-exit-active {
    opacity: 0;
    transform: translateY(100px);
    transition: all 100ms linear; }
  .--os .menu-transition-enter {
    opacity: 0;
    transform: translateY(20px); }
  .--os .menu-transition-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: all 100ms ease-out; }
  .--os .menu-transition-exit {
    opacity: 1;
    transform: translateY(0); }
  .--os .menu-transition-exit-active {
    opacity: 0;
    transform: translateY(20px);
    transition: all 100ms linear; }
  .--os .widget-enter {
    opacity: 0;
    transform: scale(0.98);
    transition: none !important; }
  .--os .widget-enter-active {
    opacity: 1;
    transform: translateY(0) scale(1);
    transition: transform 100ms linear, opacity 100ms linear !important; }
  .--os .widget-exit {
    opacity: 1;
    transform: scale(1); }
  .--os .widget-exit-active {
    opacity: 0;
    transform: scale(0.95);
    transition: transform 100ms linear, opacity 100ms linear; }
  .--os {
    cursor: default; }
  .--os body.defaultCursor, .--os body.defaultCursor * {
    cursor: default !important; }
  .--os body.defaultCursor a {
    text-decoration: none !important; }
  .--os .editing-value {
    display: inline-block;
    padding: 4px;
    margin-top: -4px;
    margin-left: -4px;
    box-sizing: border-box;
    background-color: #ececec;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.22);
    border-radius: 2px;
    position: relative;
    z-index: 9999; }
    .--os .editing-value input[type="text"] {
      width: 120px; }
    .--os .editing-value button, .--os .editing-value input, .--os .editing-value input[type="text"] {
      border: none; }
    .--os .editing-value > *:not(:last-child) {
      margin-right: 4px; }
  .--os .editable-value {
    position: relative; }
    .--os .editable-value.empty .editable-value__display {
      width: 4px;
      display: inline-block; }
    .--os .editable-value:hover .editable-value__edit {
      pointer-events: all;
      opacity: 1;
      transform: scale(1); }
    .--os .editable-value.pre .editable-value__edit {
      right: 100%; }
    .--os .editable-value .editable-value__edit {
      transform: scale(0.9);
      transition: all 100ms linear;
      position: absolute;
      opacity: 0;
      pointer-events: none;
      bottom: 0;
      margin: auto;
      z-index: 1000;
      margin-left: 0 !important;
      height: 16px; }
  .--os .editable {
    min-width: 1ch;
    min-height: 1.08em;
    border-bottom: 1px dotted #bdbdbd;
    transition: border-bottom-color 100ms linear;
    position: relative; }
    .--os .editable.empty {
      display: inline-block; }
    .--os .editable:hover {
      border-bottom-color: transparent; }
    .--os .editable:hover button {
      pointer-events: all;
      opacity: 1;
      transform: scale(1); }
    .--os .editable button {
      transform: scale(0.9);
      transition: all 100ms linear;
      position: absolute;
      opacity: 0;
      pointer-events: none;
      bottom: 0;
      margin: auto;
      z-index: 1000;
      margin-left: 0 !important;
      height: 16px; }
  .--os .has-wallpaper .os-page > .area > .widgets {
    background-color: transparent !important;
    background-image: none !important; }
  .--os .inspectOverlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9999999999999; }
  .--os .os-page {
    flex-direction: column; }
    .--os .os-page .elements button {
      vertical-align: middle; }
    .--os .os-page .elements button.windowAction, .--os .os-page .elements span.button.windowAction {
      width: 12px;
      padding: 0;
      height: 12px;
      border-radius: 50%;
      font-size: 6px;
      font-weight: bold; }
      .--os .os-page .elements button.windowAction:hover.close, .--os .os-page .elements span.button.windowAction:hover.close {
        background-color: #FF5F57; }
      .--os .os-page .elements button.windowAction:hover.minimize, .--os .os-page .elements span.button.windowAction:hover.minimize {
        background-color: #FFBE2F; }
      .--os .os-page .elements button.windowAction:hover.top, .--os .os-page .elements button.windowAction:hover.maximize, .--os .os-page .elements span.button.windowAction:hover.top, .--os .os-page .elements span.button.windowAction:hover.maximize {
        background-color: #2fd9ff; }
      .--os .os-page .elements button.windowAction:hover.embed, .--os .os-page .elements span.button.windowAction:hover.embed {
        background-color: #28CA41; }
    .--os .os-page .edit-widgets .columns {
      border: 1px solid #1a58bd;
      border-radius: 2px;
      margin-top: 8px;
      background: #e6e6e6;
      padding: 0 4px;
      display: flex; }
      .--os .os-page .edit-widgets .columns > div {
        flex: 1 1 0;
        width: 0; }
        .--os .os-page .edit-widgets .columns > div > div {
          border: 1px solid #1a58bd;
          border-radius: 5px;
          margin: 8px 4px;
          padding: 8px;
          box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.33);
          transition: all 200ms linear;
          background-color: white;
          overflow: hidden; }
          .--os .os-page .edit-widgets .columns > div > div:hover {
            background: #f7f7f7;
            box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.33); }
    .--os .os-page .interface-parameters {
      list-style-type: none;
      margin: 0;
      padding: 0;
      font-size: 12px; }
      .--os .os-page .interface-parameters > li {
        display: inline; }
        .--os .os-page .interface-parameters > li > .name {
          font-weight: 500; }
    .--os .os-page > .top-bar {
      height: 28px;
      border-bottom: 2px solid #1a58bd;
      display: flex;
      box-sizing: border-box;
      background-color: #e8e8e8;
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.35);
      font-size: 12px;
      cursor: default;
      position: relative;
      z-index: 99999; }
      .--os .os-page > .top-bar > .views {
        display: flex;
        flex: 1 1 auto;
        z-index: 2;
        background: #e8e8e8; }
        .--os .os-page > .top-bar > .views > .view {
          line-height: 27px;
          padding: 0 8px;
          display: flex;
          align-items: center;
          border-right: 1px solid #1a58bd; }
          .--os .os-page > .top-bar > .views > .view > .client {
            display: flex;
            align-items: center; }
            .--os .os-page > .top-bar > .views > .view > .client > .title {
              font-size: 10px;
              margin-right: 5px;
              font-weight: 500; }
          .--os .os-page > .top-bar > .views > .view > .maximize {
            margin-left: 4px; }
      .--os .os-page > .top-bar > .activity {
        flex: 0 0 auto;
        line-height: 32px;
        text-align: center; }
    .--os .os-page .entity-ref button {
      display: none; }
    .--os .os-page:not(.min-bar) > .area {
      bottom: 0; }
    .--os .os-page > .area {
      position: absolute;
      overflow: hidden;
      top: 0;
      bottom: 30px;
      width: 100%; }
      .--os .os-page > .area > .edit {
        position: absolute;
        bottom: 5px;
        right: 5px;
        z-index: 1;
        text-transform: lowercase;
        font-size: 13px; }
      .--os .os-page > .area > .widgets {
        height: 100%;
        display: flex;
        background-color: #e6e6e6; }
        .--os .os-page > .area > .widgets > .column {
          overflow-y: auto;
          overflow-x: hidden;
          box-sizing: border-box;
          z-index: 0;
          flex: 1 1 0;
          margin-left: -1px;
          max-height: 100%; }
          .--os .os-page > .area > .widgets > .column:first-child .widget {
            margin-left: 8px; }
          .--os .os-page > .area > .widgets > .column:last-child .widget {
            margin-right: 8px; }
          .--os .os-page > .area > .widgets > .column .widget {
            overflow: hidden;
            border: 1px solid #1a58bd;
            border-radius: 6px;
            margin: 8px 4px;
            box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.22);
            flex-grow: 1;
            transition: all 200ms linear;
            background: white;
            padding: 8px; }
            .--os .os-page > .area > .widgets > .column .widget > .title:not(:hover) > .buttons {
              opacity: 0;
              transform: scale(0.9); }
            .--os .os-page > .area > .widgets > .column .widget > .title {
              display: block;
              font-size: 15px;
              position: relative; }
              .--os .os-page > .area > .widgets > .column .widget > .title[data-react-beautiful-dnd-drag-handle="0"] {
                cursor: inherit; }
              .--os .os-page > .area > .widgets > .column .widget > .title > .buttons {
                transition: all 200ms linear;
                position: absolute;
                right: 8px;
                top: 3px;
                align-self: flex-start; }
                .--os .os-page > .area > .widgets > .column .widget > .title > .buttons > * {
                  margin-left: 2px; }
              .--os .os-page > .area > .widgets > .column .widget > .title button {
                margin-left: 3px; }
            .--os .os-page > .area > .widgets > .column .widget ul:not(.parameters) {
              margin: 2px 0; }
      .--os .os-page > .area > .windows {
        position: absolute;
        top: 0;
        z-index: 2147483000; }
        .--os .os-page > .area > .windows > .window {
          position: absolute;
          border: 2px solid #1a58bd;
          background: white;
          box-shadow: 0px 0px 9px 6px rgba(0, 0, 0, 0.1);
          border-radius: 5px;
          overflow: hidden;
          z-index: 0;
          display: flex;
          flex-direction: column; }
          .--os .os-page > .area > .windows > .window .blGrip {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 6px;
            height: 6px;
            cursor: nesw-resize;
            z-index: 9999; }
          .--os .os-page > .area > .windows > .window .brGrip {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 6px;
            height: 6px;
            cursor: nwse-resize;
            z-index: 9999; }
          .--os .os-page > .area > .windows > .window:not(.front) {
            border-color: #c8c8c8; }
            .--os .os-page > .area > .windows > .window:not(.front) > .top-bar {
              border-color: gray; }
              .--os .os-page > .area > .windows > .window:not(.front) > .top-bar, .--os .os-page > .area > .windows > .window:not(.front) > .top-bar * {
                color: gray; }
              .--os .os-page > .area > .windows > .window:not(.front) > .top-bar > .title > .handle {
                border-color: gray; }
              .--os .os-page > .area > .windows > .window:not(.front) > .top-bar button, .--os .os-page > .area > .windows > .window:not(.front) > .top-bar span.button {
                color: #c3c3c3;
                border-color: #c3c3c3; }
          .--os .os-page > .area > .windows > .window > .top-bar {
            position: relative;
            display: flex;
            border-bottom: 1px solid #1a58bd;
            background-color: #e8e8e8;
            align-items: center;
            padding: 0 4px;
            overflow: hidden;
            flex: 0 0 auto; }
            .--os .os-page > .area > .windows > .window > .top-bar .react-contextmenu-wrapper {
              position: absolute;
              right: 0;
              top: 0;
              bottom: 0;
              width: 30px; }
            .--os .os-page > .area > .windows > .window > .top-bar > .title {
              font-size: 12px;
              text-align: center;
              display: block;
              cursor: default;
              position: absolute;
              left: 40px;
              right: 40px;
              top: 0;
              height: 100%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              display: flex;
              justify-content: center;
              align-items: center; }
              .--os .os-page > .area > .windows > .window > .top-bar > .title > .handle {
                display: inline-block;
                display: none;
                width: 10px;
                height: 10px;
                border: 1px solid #212121;
                vertical-align: middle;
                margin-right: 4px;
                border-radius: 3px;
                box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.35);
                background-color: white;
                cursor: default; }
            .--os .os-page > .area > .windows > .window > .top-bar > .buttons {
              position: relative;
              display: flex;
              flex-direction: row-reverse;
              height: 21px;
              align-items: center;
              margin-right: 6px; }
              .--os .os-page > .area > .windows > .window > .top-bar > .buttons button, .--os .os-page > .area > .windows > .window > .top-bar > .buttons span.button {
                margin-right: 2px; }
          .--os .os-page > .area > .windows > .window > .client {
            overflow: auto;
            width: 100%;
            padding: 8px;
            box-sizing: border-box;
            flex: 1 1 auto;
            position: relative; }
      .--os .os-page > .area .maximizedWindow {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        background-color: white; }
    .--os .os-page > .min-bar {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 28px;
      display: flex;
      border-top: 2px solid #1a58bd;
      background-color: #e8e8e8;
      font-size: 12px;
      z-index: 10000000; }
      .--os .os-page > .min-bar > .start {
        width: 64px;
        flex: 0 0 auto;
        line-height: 26px;
        text-align: center;
        font-size: 12px;
        font-weight: 500;
        border-right: 1px solid #cdcdcd;
        z-index: 2;
        transition: background-color 100ms linear;
        cursor: pointer; }
        .--os .os-page > .min-bar > .start.open {
          background-color: white;
          font-weight: bold; }
      .--os .os-page > .min-bar > .menu {
        z-index: 1;
        position: absolute;
        bottom: 26px;
        left: 0;
        border: 2px solid #1a58bd;
        border-top-width: 1px;
        background: white;
        box-shadow: 0px 0px 9px 6px rgba(0, 0, 0, 0.1);
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        padding: 4px;
        max-height: calc(100vh - 89px);
        overflow: auto; }
        .--os .os-page > .min-bar > .menu .selector input[type="text"] {
          width: 100%;
          box-sizing: border-box; }
        .--os .os-page > .min-bar > .menu .selector {
          margin-bottom: 2px;
          display: block; }
        .--os .os-page > .min-bar > .menu a {
          color: #1a58bd; }
        .--os .os-page > .min-bar > .menu ul {
          margin: 0;
          padding: 0;
          list-style-type: none; }
        .--os .os-page > .min-bar > .menu .title {
          font-weight: 500; }
        .--os .os-page > .min-bar > .menu .section {
          margin-top: 4px; }
      .--os .os-page > .min-bar > .browser {
        padding: 0 8px;
        flex: 0 0 auto;
        line-height: 26px;
        text-align: center;
        font-size: 12px;
        border-right: 1px solid #1a58bd;
        z-index: 2;
        transition: background-color 100ms linear;
        cursor: pointer;
        background-color: #e8e8e8; }
      .--os .os-page > .min-bar .reps {
        height: 28px;
        display: flex; }
      .--os .os-page > .min-bar.terminalOpen {
        border-top-width: 1px; }
      .--os .os-page > .min-bar .rep {
        line-height: 28px;
        padding: 0 8px;
        border-right: 1px solid #1a58bd;
        cursor: default;
        cursor: pointer;
        transition: all 100ms linear;
        max-width: 132px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
        .--os .os-page > .min-bar .rep.window, .--os .os-page > .min-bar .rep.active {
          background-color: white; }
        .--os .os-page > .min-bar .rep.top, .--os .os-page > .min-bar .rep.active {
          font-weight: bold; }
    .--os .os-page .interface {
      position: relative; }
      .--os .os-page .interface.errored {
        border: 1px dashed rgba(255, 0, 0, 0.5);
        border-radius: 3px; }
    .--os .os-page .configure-interface {
      list-style-type: none;
      margin: 0;
      padding: 0; }
      .--os .os-page .configure-interface li {
        margin-bottom: 4px; }
      .--os .os-page .configure-interface .name {
        font-weight: 500; }
    .--os .os-page .interface.top {
      line-height: normal; }
      .--os .os-page .interface.top p {
        margin: 0; }
      .--os .os-page .interface.top div {
        display: inline-block; }
    .--os .os-page .jqiAoN .id {
      color: #92959b; }
  .--os .cont-topBar {
    background-color: #ebedef;
    background-image: linear-gradient(180deg, #f7f7f8, #ebedef 46%, #ebedef 39%, #dfe0e2 100%); }
    .--os .cont-topBar > * {
      border-right: 1px solid #999da3; }
    .--os .cont-topBar .widget {
      display: flex;
      align-items: center;
      height: 100%;
      padding: 0 8px;
      font-size: 11px; }
      .--os .cont-topBar .widget .parameters {
        display: none; }
      .--os .cont-topBar .widget div, .--os .cont-topBar .widget ul, .--os .cont-topBar .widget li {
        display: inline; }
      .--os .cont-topBar .widget > .title {
        font-size: 10px;
        margin-right: 5px;
        font-weight: 500; }
  .--os .cont-columns {
    box-sizing: border-box;
    overflow: auto; }
  .--os .cont-widgets {
    overflow: auto;
    padding: 8px 4px; }
    .--os .cont-widgets > *:not(:first-child) {
      margin-top: 8px; }
    .--os .cont-widgets:first-child {
      padding-left: 8px; }
    .--os .cont-widgets:last-child {
      padding-right: 8px; }
    .--os .cont-widgets .widget {
      border-color: #999da3;
      box-shadow: 0px 0px 0px 2px rgba(148, 148, 148, 0.27);
      border: 1px solid #999da3;
      border-radius: 6px;
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.22);
      background: white; }
      .--os .cont-widgets .widget > .title {
        display: block;
        width: 100%;
        font-size: 11px;
        background-color: #ebedef;
        padding: 4px 8px;
        border-bottom: 1px solid #b1b1b1;
        box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.09);
        box-sizing: border-box; }
      .--os .cont-widgets .widget > .content {
        padding: 8px; }
  .--os .widget {
    overflow: hidden; }
  .--os .elements button, .--os .elements span.button, .--os .elements a, .--os .elements span.link {
    cursor: pointer; }
  .--os .elements button:not([disabled]):hover, .--os .elements span.button:not([disabled]):hover {
    transform: scale(1.05) !important; }
  .--os .elements button:not([disabled]):active, .--os .elements span.button:not([disabled]):active {
    transform: scale(0.98) !important; }
  .--os .elements, .--os .elements button, .--os .elements input, .--os .elements textarea {
    font-family: Helvetica Neue, Helvetica;
    color: #212121; }
  .--os .elements a, .--os .elements span.link {
    transition: color 100ms linear;
    text-decoration: none; }
    .--os .elements a:visited, .--os .elements span.link:visited {
      color: #212121; }
    .--os .elements a:hover, .--os .elements span.link:hover {
      color: #1a58bd;
      text-decoration: underline; }
  .--os .elements span.button {
    display: inline-flex;
    box-sizing: border-box;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle; }
    .--os .elements span.button > * {
      flex: 0 0 auto; }
  .--os .elements button.windowAction, .--os .elements span.button.windowAction {
    display: inline-flex;
    justify-content: center;
    align-items: center; }
    .--os .elements button.windowAction > *, .--os .elements span.button.windowAction > * {
      flex: 0 0 auto; }
    .--os .elements button.windowAction .gg, .--os .elements span.button.windowAction .gg {
      --ggs: .4; }
      .--os .elements button.windowAction .gg.gg-minimize, .--os .elements span.button.windowAction .gg.gg-minimize {
        --ggs: .3; }
      .--os .elements button.windowAction .gg.gg-math-minus, .--os .elements span.button.windowAction .gg.gg-math-minus {
        --ggs: .3; }
  .--os .elements button, .--os .elements span.button {
    border: 1px solid #212121;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.35);
    color: #212121;
    font-size: 9px;
    transition: all 100ms linear; }
    .--os .elements button:focus, .--os .elements span.button:focus {
      outline: none; }
    .--os .elements button[disabled], .--os .elements span.button[disabled] {
      opacity: .5; }
  .--os .elements input[type="text"] {
    border: 1px solid #212121;
    padding: 5px;
    border-radius: 4px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.35);
    transition: all 100ms linear; }
    .--os .elements input[type="text"]:focus {
      outline: none;
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.35); }
  .--os .disconnected {
    background-color: red;
    color: white;
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 9999999999;
    padding: 5px;
    border-top-left-radius: 4px; }
  .--os .--around.parent--selector > .autocomplete {
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.35);
    background-color: white;
    margin: 0;
    left: 0;
    list-style-type: none;
    padding: 3px; }
    .--os .--around.parent--selector > .autocomplete li {
      white-space: nowrap; }
    .--os .--around.parent--selector > .autocomplete .selected {
      background-color: #b7b7b7; }
  .--os .value-content .value-content__save, .--os .value-content .value-content__edit {
    margin-left: 6px; }
  .--os .value-content-field .value-content-field__quick-entities button {
    margin-left: 6px; }
  .--os .react-contextmenu {
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: .25rem;
    color: #373a3c;
    font-size: 16px;
    margin: 2px 0 0;
    min-width: 160px;
    outline: none;
    opacity: 0;
    padding: 5px 0;
    pointer-events: none;
    text-align: left;
    transition: opacity 250ms ease !important; }
  .--os .react-contextmenu.react-contextmenu--visible {
    opacity: 1;
    pointer-events: auto;
    z-index: 9999; }
  .--os .react-contextmenu-item {
    background: 0 0;
    border: 0;
    color: #373a3c;
    cursor: pointer;
    font-weight: 400;
    line-height: 1.5;
    padding: 3px 20px;
    text-align: inherit;
    white-space: nowrap; }
  .--os .react-contextmenu-item.react-contextmenu-item--active,
  .--os .react-contextmenu-item.react-contextmenu-item--selected {
    color: #fff;
    background-color: #20a0ff;
    border-color: #20a0ff;
    text-decoration: none; }
  .--os .react-contextmenu-item.react-contextmenu-item--disabled,
  .--os .react-contextmenu-item.react-contextmenu-item--disabled:hover {
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.15);
    color: #878a8c; }
  .--os .react-contextmenu-item--divider {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    cursor: inherit;
    margin-bottom: 3px;
    padding: 2px 0; }
  .--os .react-contextmenu-item--divider:hover {
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.15); }
  .--os .react-contextmenu-item.react-contextmenu-submenu {
    padding: 0; }
  .--os .react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item:after {
    content: "▶";
    display: inline-block;
    position: absolute;
    right: 7px; }

html.theme-Light .os-page .min-bar {
  box-shadow: 0px 0px 2px 1px #00000017;
  border-top-color: #dcdcdc;
  background-color: white;
  border-top-width: 1px; }

html.theme-Light .os-page > .min-bar .rep {
  border-right-color: #cdcdcd; }

html.theme-Light .os-page > .top-bar {
  border-bottom-color: #999da3;
  background-color: white;
  border-bottom-width: 1px; }

html.theme-Light .os-page > .min-bar .rep.window, html.theme-Light .os-page > .min-bar .rep.active {
  background-color: #ebedef; }

html.theme-Light .os-page > .min-bar > .start, html.theme-Light .os-page > .min-bar > .browser {
  border-color: #cdcdcd; }

html.theme-Light .os-page > .top-bar > .views > .view {
  border-color: #999da3; }

html.theme-Light .os-page > .top-bar > .views {
  background-color: white; }

html.theme-Light .os-page > .top-bar > .start.open {
  background-color: #ebedef; }

html.theme-Light .os-page > .area > .widgets > .column .widget {
  border-color: #999da3;
  box-shadow: 0px 0px 0px 2px rgba(148, 148, 148, 0.27);
  margin: 15px 8px;
  position: relative; }
  html.theme-Light .os-page > .area > .widgets > .column .widget > .title {
    font-size: 11px;
    background-color: #ebedef;
    margin: -8px;
    padding: 4px 8px;
    margin-bottom: 5px;
    border-bottom: 1px solid #b1b1b1;
    box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.09);
    box-sizing: border-box; }

html.theme-Light .os-page > .area > .widgets > .column .widget:hover {
  box-shadow: 0px 0px 0px 3px rgba(148, 148, 148, 0.29); }

html.theme-Light .os-page > .area > .widgets > .column:first-child .widget {
  margin-left: 16px; }

html.theme-Light .os-page > .area > .widgets > .column:last-child .widget {
  margin-right: 16px; }

html.theme-Light .os-page > .area > .windows > .window {
  border-color: #dcdcdc;
  border-width: 1px;
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.12);
  border-radius: 6px; }

html.theme-Light .os-page > .area > .widgets > .column .widget > * {
  position: relative;
  z-index: 1; }

html.theme-Light .os-page > .area > .widgets > .column .widget:before {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 106px;
  background-image: linear-gradient(180deg, white 0%, white 94%, #ececec 100%);
  content: ''; }

html.theme-Light .os-page > .area > .windows > .window {
  background: linear-gradient(337deg, #fafafa, #fff); }

html.theme-Light .os-page > .area > .windows > .window:not(.front) {
  border-color: #eaeaea;
  box-shadow: 0px 0px 4px 1px #00000014; }
  html.theme-Light .os-page > .area > .windows > .window:not(.front) .top-bar {
    background-color: #ebedef;
    border-bottom-color: #c3c3c3; }

html.theme-Light .os-page > .area > .windows > .window > .top-bar {
  border-color: #bcbcbc;
  background-color: #ebedef; }

html.theme-Light .kNjWKu .client .nav {
  border-color: #999da3 !important; }

html.theme-Light .os-page > .area {
  bottom: 0;
  top: 0; }
  html.theme-Light .os-page > .area.showBar {
    bottom: 29px; }

html.theme-Light .os-page > .terminal {
  box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.09);
  border-top-color: #999da3;
  background-color: white;
  border-top-width: 1px; }

html.theme-Light .os-page > .area > .widgets {
  background-color: #ebedef; }

html.theme-Light .os-page .elements button, html.theme-Light .os-page .elements span.button {
  background-image: linear-gradient(180deg, #f7f7f8, #ebedef 46%, #ebedef 39%, #dfe0e2 100%); }

html.theme-Light .os-page .elements, html.theme-Light .os-page .elements span.button, html.theme-Light .os-page .elements input[type="text"],
html.theme-Light .os-page > .area > .windows > .window > .top-bar > .title > .handle {
  border-color: #c3c3c3; }

html.theme-Light .os-page .elements button, html.theme-Light .os-page .elements input, html.theme-Light .os-page .elements textarea,
html.theme-Light .os-page .elements a:visited, html.theme-Light .os-page .elements span.link:visited, html.theme-Light .os-page .elements span.button {
  color: #212121; }

html.theme-Light .os-page > .terminal > .contents .line.active .in:before {
  border-color: #999da3; }

html.theme-Light .os-page > .min-bar > .menu {
  border-color: #999da3;
  border-width: 1px;
  box-shadow: 0px 0px 0px 3px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

html.theme-Light .--around.parent--terminal > .autocomplete {
  border-color: #999da3;
  border-width: 1px;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  overflow: hidden; }

html.theme-Light .os-page .elements a:hover, html.theme-Light .os-page .elements span.link:hover {
  color: #385f9f; }

html.theme-Light .os-page .--around.parent--terminal > .autocomplete > span.active {
  background-color: #385f9f; }

html.theme-Light .os-page .min-bar,
html.theme-Light .os-page > .top-bar,
html.theme-Light .os-page > .top-bar > .views,
html.theme-Light .os-page > .terminal {
  background-color: white; }

html.theme-Light .os-page .min-bar,
html.theme-Light .os-page > .top-bar,
html.theme-Light .os-page > .top-bar > .views {
  background-color: #ebedef;
  background-image: linear-gradient(180deg, #f7f7f8, #ebedef 46%, #ebedef 39%, #dfe0e2 100%); }

html.theme-Light .os-page > .min-bar .rep.window, html.theme-Light .os-page > .min-bar .rep.active,
html.theme-Light .os-page > .top-bar > .start.open,
html.theme-Light .os-page > .area > .widgets > .column .widget > .title,
html.theme-Light .os-page > .area > .windows > .window .top-bar,
html.theme-Light .os-page > .area > .windows > .window:not(.front) .top-bar {
  background-color: #ebedef; }

html.theme-Light .os-page > .area > .widgets > .column .widget > .title,
html.theme-Light .os-page > .area > .windows > .window .top-bar,
html.theme-Light .os-page > .area > .windows > .window:not(.front) .top-bar {
  background-image: linear-gradient(180deg, #f7f7f8, #ebedef 46%, #ebedef 39%, #dfe0e2 100%); }

html.theme-Light .os-page > .area > .widgets {
  background-color: #ebedef;
  background-color: #dee0e3; }

html.theme-Light .os-page .editing-value {
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.12);
  background-color: white; }

html.theme-Light .os-page > .min-bar .rep.open, html.theme-Light .os-page > .min-bar .rep.active {
  background-color: white; }
